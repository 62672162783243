<template>
  <div class="conter_box">
    <div class="title">
      <span class="title_text">{{ title }}</span>
      <span class="title_value">{{ titleValue }}</span>
      <div class="glory">{{ title2 }}</div>
    </div>
    <div v-if="echartsStatus" class="idx-Lbox-3" ref="xjdwEcharts"></div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    title2: String,
    xjdwData: Object,
  },
  components: {},
  data() {
    return {
      titleValue: "",
      echartsStatus: true,
    };
  },
  watch: {
    xjdwData: {
      handler(newData, oldData) {
        this.echartsStatus = false;
        this.echartsData();
      },
      // immediate: true, // 是否进行第一次监测
      deep: true, // 深度监测,监测引用数据类型是必须深度监测
    },
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        {
          this.echartsData();
        }
      }, 1000);
    });
  },
  methods: {
    echartsData() {
      this.echartsStatus = true;
      let titleValue = 0;
      this.xjdwData.data.ydata.forEach((item) => {
        titleValue += item.value * 1;
      });
      this.titleValue = titleValue * 1;
      let ydata = this.xjdwData.data.ydata;
      let xdata = [];
      let myChart = this.$echarts.init(this.$refs.xjdwEcharts);
      let color = ["#8A1D1D", "#E36594", "#FEB1CE"];
      let option = {
        title: {
          subtext: "",
          textStyle: {
            color: "#FFFFFF",
            fontSize: 14, //文字的字体大小
          },
          left: "23%",
          top: "85.5%",
        },
        color: color,
        legend: {
          orient: "vertical",
          top: "20%",
          left: "55%",
          bottom: "0%",
          data: xdata,
          itemWidth: 10,
          itemHeight: 10,
          textStyle: {
            //图例文字的样式
            color: "#fff",
            fontSize: 12,
            padding: 5,
          },
          formatter: function (name) {
            return "" + name;
          },
        },
        series: [
          {
            type: "pie",
            clockwise: false, //饼图的扇区是否是顺时针排布
            minAngle: 2, //最小的扇区角度（0 ~ 360）
            radius: ["40%", "65%"],
            center: ["50%", "40%"],
            avoidLabelOverlap: false,
            itemStyle: {
              //图形样式
              normal: {
                borderWidth: 1,
              },
            },
            label: {
              normal: {
                show: false,
                position: "center",
                formatter: "{text|{b}}\n{c}",
                rich: {
                  text: {
                    color: "#fff",
                    fontSize: 14,
                    align: "center",
                    verticalAlign: "middle",
                    padding: 8,
                  },
                  value: {
                    color: "#fff",
                    fontSize: 14,
                    align: "center",
                    verticalAlign: "middle",
                  },
                },
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: 22,
                  color: "#fff",
                },
              },
            },
            data: ydata,
          },
        ],
      };

      myChart.setOption(option);
      myChart.currentIndex = 0;
      let time = undefined;

      setTimeout(() => {
        myChart.on("mouseover", (params) => {
          clearInterval(time);
          let allindex = [];
          ydata.forEach((item, i) => {
            allindex.push(i);
          });
          myChart.dispatchAction({
            type: "downplay",
            dataIndex: allindex,
          });
          myChart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: params.dataIndex,
          });
          myChart.currentIndex = params.dataIndex;
        });

        myChart.on("mouseout", function () {
          time = setInterval(function () {
            let dataLen = option.series[0].data.length;
            // 取消之前高亮的图形
            let allindex = [];
            ydata.forEach((item, i) => {
              allindex.push(i);
            });
            myChart.dispatchAction({
              type: "downplay",
              dataIndex: allindex,
            });
            myChart.currentIndex = (myChart.currentIndex + 1) % dataLen;
            // 高亮当前图形
            myChart.dispatchAction({
              type: "highlight",
              seriesIndex: 0,
              dataIndex: myChart.currentIndex,
            });
          }, 3000);
        });
        myChart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: 0,
        });
      });
      clearInterval(time);
      clearTimeout();
      time = setInterval(function () {
        let dataLen = option.series[0].data.length;
        // 取消之前高亮的图形
        let allindex = [];
        ydata.forEach((item, i) => {
          allindex.push(i);
        });
        myChart.dispatchAction({
          type: "downplay",
          dataIndex: allindex,
        });
        myChart.currentIndex = (myChart.currentIndex + 1) % dataLen;
        // 高亮当前图形
        myChart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: myChart.currentIndex,
        });
      }, 3000);

      // 使用刚指定的配置项和数据显示图表。
      /*myChart.setOption(option);*/
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.conter_box {
  position: absolute;
  left: 0;
  top: 0;
  width: 97.4%;
  height: 97.4%;
  margin: 0.2vw;
  background: rgba(255, 212, 228, 0.4);
  .title {
    padding: 0.1vw 1vw 0.5vw;
    .title_text {
      font-size: 1.05vw;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #ffd4e4;
      margin-right: 1vw;
    }
    .title_value {
      font-size: 1.68vw;
      font-family: hy;
      font-weight: 400;
      font-style: italic;
      color: #ffd901;
    }
    .glory {
      height: 1.9vh;
      font-size: 0.9vw;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #ffb3cf;
      letter-spacing: 0.1vw;
      margin: 0.5vw 0 0 0.5vw;
    }
  }
}

.idx-Lbox-3 {
  height: 20vh;
  padding: 0vh;
  margin-top: 0vh;
}
</style>