<template>
  <div class="dialog_body">
    <div class="dialog_contert">
      <div class="dialog_title">
        <div class="title">{{ dialogTitle }}</div>
        <div class="close_X" @click="dialogClose({ type: type, num: 0 })">
          <img src="../../../assets/image/zhfe/dialogClose.png" alt="" />
        </div>
      </div>
      <div class="dialog_conter">
        <div class="conter_box">
          <el-form
            :model="editObj"
            :rules="rules"
            ref="ruleForm"
            label-width="6.25vw"
            class="demo-ruleForm"
          >
            <el-form-item :label="leftTable[0]" prop="dataDetailed">
              <el-input
                onKeypress="javascript:if(event.keyCode == 32)event.returnValue = false;"
                type="textarea"
                :rows="3"
                v-model="editObj.dataDetailed"
              ></el-input>
            </el-form-item>
            <el-form-item :label="leftTable[1]" prop="dataField1">
              <el-input
                onKeypress="javascript:if(event.keyCode == 32)event.returnValue = false;"
                v-if="type == 'jnhjqk'"
                type="textarea"
                :rows="3"
                v-model="editObj.dataField1"
              ></el-input>
              <el-input
                onKeypress="javascript:if(event.keyCode == 32)event.returnValue = false;"
                v-if="type == 'pyfh'"
                v-model="editObj.dataField1"
              ></el-input>
            </el-form-item>
            <el-form-item :label="leftTable[2]" prop="dataField2">
              <el-select
                v-if="type == 'jnhjqk'"
                v-model="editObj.dataField2"
                placeholder="请选择获奖级别"
              >
                <el-option label="国家级" value="国家级"></el-option>
                <el-option label="省级" value="省级"></el-option>
                <el-option label="市级" value="市级"></el-option>
              </el-select>
              <el-date-picker
                v-if="type == 'pyfh'"
                v-model="editObj.dataField2"
                type="date"
                placeholder="选择日期"
                format="yyyy 年 MM 月"
                value-format="yyyy年MM月"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="dialog_footer">
        <div class="btn_box">
          <div @click="dialogClose({ type: type, num: 0 })" class="close_btn">
            取 消
          </div>
          <div @click="dialogSubmit" class="submit_btn">确 定 修 改</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { putDataDetail } from "@/api/dtdata";
export default {
  props: {
    type: String, // jnhjqk,pyfh
    dialogTitle: String,
    editObj: Object,
  },
  components: {},
  data() {
    return {
      leftTable: [],
      rules: {
        dataDetailed: [
          {
            required: true,
            message: `请输入${this.type == "jnhjqk" ? "奖项" : "项目名称"}`,
            trigger: "blur",
          },
        ],
        dataField1: [
          {
            required: true,
            message: `请输入${this.type == "jnhjqk" ? "颁发单位" : "责任人"}`,
            trigger: "blur",
          },
        ],
        dataField2: [
          {
            required: true,
            message: `${
              this.type == "jnhjqk" ? "请选择获奖级别" : "请选择计划完成时间"
            }`,
            trigger: "change",
          },
        ],
      },
    };
  },
  watch: {},
  created() {
    switch (this.type) {
      case "jnhjqk":
        this.leftTable = ["奖项", "颁发单位", "获奖级别"];
        break;
      case "pyfh":
        this.leftTable = ["项目名称", "责任人", "计划完成时间"];
        break;
    }
  },
  mounted() {},
  methods: {
    dialogSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          putDataDetail(this.editObj)
            .then((res) => {
              if (res.code == 200) {
                this.$message.success("修改成功");
                setTimeout(() => {
                  this.dialogClose({ type: this.type, num: 1 });
                }, 1000);
              } else {
                this.$message.info(res.msg);
              }
            })
            .catch((err) => {});
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    dialogClose(obj) {
      this.$refs.ruleForm.resetFields();
      this.$emit("closeRightDialog", obj);
    },
  },
};
</script>
<style lang='scss' scoped>
.dialog_body {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  font-size: 0.9vw;
  .dialog_contert {
    width: 40vw;
    min-height: 30vh;
    background: url("../../../assets/image/zhfe/dialogBg.png") no-repeat;
    background-size: 100% 100%;
    background-position: center;
    .dialog_title {
      display: flex;
      justify-content: center;
      position: relative;
      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 12vw;
        height: 2.5vw;
        background: #cd80a2;
        border-bottom-left-radius: 0.5vw;
        border-bottom-right-radius: 0.5vw;
        border: 0.1vw solid;
        font-size: 1vw;
      }
      .close_X {
        position: absolute;
        right: -1vw;
        top: -1vw;
        cursor: pointer;
        img {
          width: 2vw;
          height: 2vw;
        }
      }
    }
    .dialog_conter {
      padding: 2vw;
      .conter_box {
      }
    }
    .dialog_footer {
      padding: 2vw 0vw;
      .btn_box {
        color: #ffd4e4;
        display: flex;
        align-items: center;
        justify-content: center;
        div {
          border: 0.1vw solid #ffd4e4;
          width: 8vw;
          height: 2.5vw;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        .close_btn {
          margin-right: 2vw;
        }
      }
    }
  }
}
::v-deep {
  .el-form-item {
    margin-bottom: 1.15vw;
  }
  .el-form-item__label {
    color: rgba(255, 212, 227, 1);
    font-size: 0.73vw;
    line-height: 2.1vw;
    padding: 0 0.62vw 0 0;
  }
  .el-textarea__inner,
  .el-input__inner {
    color: rgba(255, 212, 227, 1);
    background-color: transparent;
    &::placeholder {
      color: rgba(255, 212, 227, 1);
    }
  }
  .el-select .el-input .el-select__caret {
    color: rgba(255, 212, 227, 1);
  }
  .el-input__prefix,
  .el-input__suffix {
    color: rgba(255, 212, 227, 1);
  }
  .el-textarea {
    font-size: 0.75vw;
  }
  .el-form-item__content {
    line-height: 2vw;
    font-size: 0.75vw;
  }
  .el-input {
    font-size: 0.73vw;
  }
  .el-input__icon {
    line-height: 0;
  }
  .el-input--suffix .el-input__inner {
    padding-right: 1.6vw;
  }
  .el-input__inner {
    height: 2.2vw;
    line-height: 2.2vw;
    padding: 0 0.76vw;
  }
  .el-select .el-input .el-select__caret {
    font-size: 0.73vw;
  }
}
</style>