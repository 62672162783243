<template>
  <div class="dialog_body">
    <div class="dialog_contert">
      <div class="dialog_title">
        <div class="title">{{ dialogTitle }}</div>
        <div class="close_X" @click="dialogClose({ type: type, num: 0 })">
          <img src="../../../assets/image/zhfe/dialogClose.png" alt="" />
        </div>
      </div>
      <div class="dialog_conter">
        <div class="conter_box">
          <div v-if="leftTable.length > 0" class="left">
            <div class="table_title">
              <div
                class="title_item"
                v-for="(item, index) in leftTable"
                :key="index"
              >
                {{ item }} ：
              </div>
            </div>
          </div>
          <div class="right">
            <div class="table_title">
              <div
                class="title_item"
                v-for="(item, index) in editObj.xdata"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
            <div class="table_data">
              <div class="data_jgwmgw">
                <div
                  class="data_item"
                  v-for="(item, index) in editObj.jgwmgw.data.ydata"
                  :key="index"
                >
                  <el-input-number
                    onKeypress="javascript:if(event.keyCode == 32)event.returnValue = false;"
                    v-model="item.dataDetailed"
                    :min="0"
                    :precision="0"
                  ></el-input-number>
                </div>
              </div>
              <div class="data_sbhqjt">
                <div
                  class="data_item"
                  v-for="(item, index) in editObj.sbhqjt.data.ydata"
                  :key="index"
                >
                  <el-input-number
                    onKeypress="javascript:if(event.keyCode == 32)event.returnValue = false;"
                    v-model="item.dataDetailed"
                    :min="0"
                    :precision="0"
                  ></el-input-number>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="total_box"></div>
      </div>
      <div class="dialog_footer">
        <div class="btn_box">
          <div @click="dialogClose({ type: type, num: 0 })" class="close_btn">
            取消
          </div>
          <div @click="dialogSubmit" class="submit_btn">确定修改</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { batchDataDetail } from "@/api/dtdata";
export default {
  props: {
    type: String, // xjdw,xjnx,xjjt
    dialogTitle: String,
    editObj: Object,
  },
  components: {},
  data() {
    return {
      leftTable: [],
    };
  },
  watch: {},
  created() {
    console.log(this.editObj);
    switch (this.type) {
      case "xjdw":
        this.leftTable = ["隐藏", "巾帼文明岗位", "三八红旗集体"];
        break;
      case "xjnx":
        this.leftTable = ["隐藏", "总数", "三八红旗手"];
        break;
    }
  },
  mounted() {},
  methods: {
    dialogClose(obj) {
      this.$emit("closeLeftDialog", obj);
    },
    dialogSubmit() {
      let list = [
        ...this.editObj.jgwmgw.data.ydata,
        ...this.editObj.sbhqjt.data.ydata,
      ];
      switch (this.type) {
        case "xjdw":
          this.requestFun(list);
          break;
        case "xjnx":
          this.requestFun(list);
          break;
        case "xjjt":
          this.requestFun(list);
          break;
      }
    },
    requestFun(list) {
      batchDataDetail(list)
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("修改成功");
            setTimeout(() => {
              this.dialogClose({ type: this.type, num: 1 });
            }, 1000);
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {});
    },
  },
};
</script>
<style lang='scss' scoped>
.dialog_body {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  font-size: 0.9vw;
  .dialog_contert {
    width: 40vw;
    min-height: 30vh;
    background: url("../../../assets/image/zhfe/dialogBg.png") no-repeat;
    background-size: 100% 100%;
    background-position: center;
    .dialog_title {
      display: flex;
      justify-content: center;
      position: relative;
      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 12vw;
        height: 2.5vw;
        background: #cd80a2;
        border-bottom-left-radius: 0.5vw;
        border-bottom-right-radius: 0.5vw;
        border: 0.1vw solid;
        font-size: 1vw;
      }
      .close_X {
        position: absolute;
        right: -1vw;
        top: -1vw;
        cursor: pointer;
        img {
          width: 2vw;
          height: 2vw;
        }
      }
    }
    .dialog_conter {
      padding: 2vw;
      .conter_box {
        display: flex;
        justify-content: center;
        .left {
          margin-right: 2vw;
          width: 7vw;
          .table_title {
            font-size: 0.8vw;
            color: rgba(255, 212, 227, 1);
            .title_item {
              line-height: 2vw;
              margin-bottom: 0.5vw;
              &:first-child {
                opacity: 0;
              }
            }
          }
        }
        .right {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          .table_title {
            font-size: 0.8vw;
            display: flex;
            color: rgba(255, 212, 227, 1);
            .title_item {
              width: 9vw;
              text-align: center;
              line-height: 2vw;
            }
          }
          .table_data {
            .data_jgwmgw,
            .data_sbhqjt {
              display: flex;
              margin-bottom: 1vw;
              .data_item {
                width: 9vw;
                text-align: center;
              }
            }
          }
        }
      }
    }
    .dialog_footer {
      padding: 2vw 0vw;
      .btn_box {
        font-size: 0.8vw;
        color: #ffd4e4;
        display: flex;
        align-items: center;
        justify-content: center;
        div {
          border: 0.1vw solid #ffd4e4;
          width: 8vw;
          height: 2.5vw;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        .close_btn {
          margin-right: 2vw;
        }
      }
    }
  }
}
::v-deep {
  .el-input {
    font-size: 0.7vw;
  }
  .el-input-number {
    width: 7vw;
    line-height: 2vw;
  }
  .el-input__inner {
    background-color: transparent;
    color: rgba(255, 212, 227, 1);
    height: 2.1vw;
    line-height: 2.1vw;
  }
  .el-input-number .el-input__inner {
    padding: 0 2.61vw;
  }
  .el-input-number__decrease,
  .el-input-number__increase {
    background: transparent;
    color: rgb(255, 212, 227);
    width: 2.05vw;
    font-size: 0.7vw;
  }
}
</style>