<template>
  <div class="xjjt_module">
    <div class="title">先进家庭</div>
    <div class="centent">
      <div class="left">
        <div class="conter">
          <div
            class="conter_row"
            v-for="(item, index) in xjjt.data"
            :key="index"
          >
            <span>{{ item.name }}</span
            ><span>{{ item.value }}</span>
          </div>
        </div>
      </div>
      <div class="right">
        <div v-if="echartsStatus" class="idx-Lbox-3" ref="xjdwEcharts"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    xjjt: Object,
  },
  components: {},
  data() {
    return {
      echartsStatus: true,
    };
  },
  watch: {
    xjjt: {
      handler(newData, oldData) {
        this.echartsStatus = false;
        this.echartsData();
      },
      // immediate: true, // 是否进行第一次监测
      deep: true, // 深度监测,监测引用数据类型是必须深度监测
    },
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      {
        setTimeout(() => {
          this.echartsData();
        }, 1000);
      }
    });
  },
  methods: {
    echartsData() {
      this.echartsStatus = true;
      let num = this.allNum;
      let ydata = [];
      let xdata = [];
      this.xjjt.data.forEach((item) => {
        ydata.push({ name: item.dataName, value: item.dataDetailed });
      });
      let myChart = this.$echarts.init(this.$refs.xjdwEcharts);
      let color = ["#FEB1CE", "#E36594", "#8A1D1D"];
      let option = {
        title: {
          subtext: "",
          textStyle: {
            color: "#FFFFFF",
            fontSize: 14, //文字的字体大小
          },
          left: "23%",
          top: "85.5%",
        },
        color: color,
        legend: {
          orient: "vertical",
          top: "20%",
          left: "55%",
          bottom: "0%",
          data: xdata,
          itemWidth: 10,
          itemHeight: 10,
          textStyle: {
            //图例文字的样式
            color: "#fff",
            fontSize: 12,
            padding: 5,
          },
          formatter: function (name) {
            return "" + name;
          },
        },
        series: [
          {
            type: "pie",
            clockwise: false, //饼图的扇区是否是顺时针排布
            minAngle: 2, //最小的扇区角度（0 ~ 360）
            radius: ["40%", "65%"],
            center: ["75.6%", "45%"],
            avoidLabelOverlap: false,
            itemStyle: {
              //图形样式
              normal: {
                borderWidth: 1,
              },
            },
            label: {
              normal: {
                show: false,
                position: "center",
                formatter: "{text|{b}}\n{c}",
                rich: {
                  text: {
                    color: "#fff",
                    fontSize: 14,
                    align: "center",
                    verticalAlign: "middle",
                    padding: 8,
                  },
                  value: {
                    color: "#fff",
                    fontSize: 14,
                    align: "center",
                    verticalAlign: "middle",
                  },
                },
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: 22,
                  color: "#fff",
                },
              },
            },
            data: ydata,
          },
        ],
      };

      myChart.setOption(option);
      myChart.currentIndex = 0;
      let time = undefined;
      clearInterval(time);
      clearTimeout();
      setTimeout(() => {
        myChart.on("mouseover", (params) => {
          clearInterval(time);
          let allindex = [];
          ydata.forEach((item, i) => {
            allindex.push(i);
          });
          myChart.dispatchAction({
            type: "downplay",
            dataIndex: allindex,
          });
          myChart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: params.dataIndex,
          });
          myChart.currentIndex = params.dataIndex;
        });

        myChart.on("mouseout", function () {
          time = setInterval(function () {
            let dataLen = option.series[0].data.length;
            // 取消之前高亮的图形
            let allindex = [];
            ydata.forEach((item, i) => {
              allindex.push(i);
            });
            myChart.dispatchAction({
              type: "downplay",
              dataIndex: allindex,
            });
            myChart.currentIndex = (myChart.currentIndex + 1) % dataLen;
            // 高亮当前图形
            myChart.dispatchAction({
              type: "highlight",
              seriesIndex: 0,
              dataIndex: myChart.currentIndex,
            });
          }, 3000);
        });
        myChart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: 0,
        });
      });
      time = setInterval(function () {
        let dataLen = option.series[0].data.length;
        // 取消之前高亮的图形
        let allindex = [];
        ydata.forEach((item, i) => {
          allindex.push(i);
        });
        myChart.dispatchAction({
          type: "downplay",
          dataIndex: allindex,
        });
        myChart.currentIndex = (myChart.currentIndex + 1) % dataLen;
        // 高亮当前图形
        myChart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: myChart.currentIndex,
        });
      }, 3000);

      // 使用刚指定的配置项和数据显示图表。
      /*myChart.setOption(option);*/
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.xjjt_module {
  .title {
    background: url("../../../assets/image/zhfe/hometitle.png") no-repeat;
    background-size: 100% 100%;
    background-position: center;
    height: 2vw;
    line-height: 2vw;
    padding: 0 1vw;
    margin-top: 1vw;
    font-size: 0.9vw;
    // font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #ffd4e4;
  }
  .centent {
    position: relative;
    .left {
      position: absolute;
      top: 0vw;
      left: 3vw;
      color: #fff;
      .conter {
        .conter_row {
          width: 9.35vw;
          height: 4vh;
          margin-top: 0.7vw;
          display: flex;
          align-items: center;
          justify-content: space-around;

          &:nth-child(3n + 1) {
            background: #ffb3cf;
          }
          &:nth-child(3n + 2) {
            background: #ff6ba2;
          }
          &:nth-child(3n + 3) {
            background: #8e1b46;
          }
          span {
            &:first-child {
              width: 3vw;
              font-size: 1vw;
              text-align: left;
            }
            &:last-child {
              font-size: 1.89vw;
              font-family: hy;
              font-weight: 400;
              font-style: italic;
            }
          }
        }
      }
    }
  }
  .idx-Lbox-3 {
    height: 20vh;
    padding: 0vh;
    margin-top: 0vh;
  }
}
</style>