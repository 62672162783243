<template>
  <div class="grb_dialog">
    <div v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)" class="dialog_conter">
      <div class="conter_bg">
        <div class="img_box">
          <img class="grb_left_img" src="../../../assets/image/zhfe/grb_left_img.png" alt="" />
          <img class="grb_right_img" src="../../../assets/image/zhfe/grb_right_img.png" alt="" />
          <img @click="closeDialog" class="grb_close_btn" src="../../../assets/image/zhfe/grb_close_btn.png" alt="" />
        </div>
        <div class="conter_box">
          <div v-if="grbDetailData.personalPhoto" class="personal_photo">
            <!-- <div class="personal_photo"> -->
            <img :src="grbDetailData.personalPhoto" alt="" />
            <!-- <img src="../../../assets/image/zhfe/dialogBg.png" alt="" /> -->
          </div>
          <div class="name">{{ grbDetailData.name }}</div>
          <div class="honor" v-for="(item, index) in grbDetailData.honorDesc" :key="index">
            <!-- {{ grbDetailData.honor }} -->
            <div class="honor_item">{{ item }}</div>
          </div>
          <div v-if="grbDetailData.briefStory" class="deeds">简要事迹</div>
          <div class="briefstory">
            <div class="briefstory_item" v-for="(item, index) in grbDetailData.briefStory">
              {{ item }}。
            </div>
            <!-- <div class="briefstory_item">{{ grbDetailData.briefStory }}</div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getGrbDetail } from "@/api/zhfeAward";

export default {
  props: {
    grbDetailDialog: Boolean,
    grbRow: Object,
  },
  components: {},
  data() {
    return {
      loading: false,
      grbDetailData: {},
    };
  },
  watch: {},
  async created() {
    await this.getData();
  },
  mounted() { },
  methods: {
    async getData() {
      this.loading = true;
      let { data } = await getGrbDetail(this.grbRow.id);
      if (data.briefStory) {
        let a = data.briefStory.split("。");
        let b;
        if (a) {
          b = a.filter((item) => {
            return item != "";
          });
        }
        data.briefStory = b;
      }
      if (data.honorDesc) {
        data.honorDesc = data.honorDesc.replace("；", "、");
        data.honorDesc = data.honorDesc.split("、");
      }
      console.log(data);
      this.grbDetailData = data;
      this.loading = false;
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>
<style lang='scss' scoped>
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: transparent;
  border-radius: 5px;
  background-color: #d29988;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: transparent;
  background-color: #a07366;
}

.grb_dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;

  .dialog_conter {
    padding: 2.765vw 2.92vw;
    background: red;
    position: relative;
    min-width: 33vw;
    max-width: 33vw;

    .conter_bg {
      // width: 27vw;
      // min-height: 35.5vh;
      background: linear-gradient(270deg,
          rgba(252, 205, 186, 0.89),
          rgba(248, 202, 157, 0.89));
      text-align: center;
      padding: 2.5vh 2.5vh 2.5vh 0.5vh;
      width: 27.2vw;
      overflow: hidden;

      .img_box {
        img {
          position: absolute;
        }

        .grb_left_img {
          top: -2vw;
          left: -2vw;
          width: 7.25vw;
          height: 17.8vh;
        }

        .grb_right_img {
          top: -4vw;
          right: 0vw;
          width: 7.25vw;
          height: 25vh;
        }

        .grb_close_btn {
          top: 0.2vw;
          right: 0.2vw;
          cursor: pointer;
          z-index: 1;
          width: 2.15vw;
          height: 4.5vh;
        }
      }

      .conter_box {
        width: 104%;
        max-height: 66vh;
        overflow-y: auto;
        overflow-x: hidden;



        .personal_photo {
          width: 98%;

          img {
            width: 24.7vw;
            height: 36vh;
            margin-bottom: 3.6vh;
          }
        }

        .name {
          font-size: 4vh;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #080707;
          background: linear-gradient(183deg, #f28e26 0%, #fd644f 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-bottom: 1.45vw;
        }

        .honor {
          width: 98%;

          .honor_item {
            font-size: 3vh;
            font-weight: 700;
            color: #c7000b;
            line-height: 2vw;
            background: linear-gradient(183deg, #f28e26 0%, #fd644f 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        .deeds {
          margin-top: 1.7vw;
          font-size: 2vh;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #e16c94;
          background: linear-gradient(183deg, #f28e26 0%, #fd644f 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-bottom: 1.2vw;
        }

        .briefstory {
          font-size: 1.8vh;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          line-height: 1.3vw;
          text-align: left;
          padding: 1vw;

          .briefstory_item {
            text-indent: 2vw;
          }
        }
      }
    }
  }
}
</style>