<template>
  <div class="xjnx_module">
    <div class="title">先进女性</div>
    <div class="content">
      <div class="xjnx_value">
        <span v-for="(item, index) in xjnx.value" :key="index">{{ item }}</span>
      </div>
      <div class="table_box">
        <div class="left_lable">
          <div
            class="lable_text"
            v-for="(item, index) in xjnx.table.leftLable"
            :key="index"
          >
            <div class="text">{{ item }}</div>
          </div>
        </div>
        <div class="right_table">
          <div class="top_lable">
            <div
              class="lable_text"
              v-for="(item, index) in xjnx.table.dataLable"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
          <div class="lable_value">
            <div
              class="value_row"
              v-for="(item, index) in xjnx.table.data"
              :key="index"
            >
              <div class="value">{{ item.dataDetailed }}</div>
              <!-- <div class="value">{{ item.nationalLevel }}</div>
              <div class="value">{{ item.provincialLevel }}</div>
              <div class="value">{{ item.municipalLevel }}</div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    xjnx: Object,
  },
  components: {},
  data() {
    return {};
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang='scss' scoped>
.xjnx_module {
  .title {
    background: url("../../../assets/image/zhfe/hometitle.png") no-repeat;
    background-size: 100% 100%;
    background-position: center;
    height: 2vw;
    line-height: 2vw;
    padding: 0 1vw;
    margin-top: 1vw;
    font-size: 0.9vw;
    // font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #ffd4e4;
  }
  .content {
    padding: 0.5vw 1vw;
    display: flex;
    flex-wrap: wrap;
    .xjnx_value {
      width: 100%;
      span {
        font-size: 2.4vw;
        font-family: hy;
        font-weight: 400;
        font-style: italic;
        color: #ffd901;
        margin-right: 0.3vw;
        background: #0e2a53;
        display: inline-block;
        padding: 0 0.5vw 0 0.2vw;
      }
    }
    .table_box {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      font-size: 1vw;
      .left_lable {
        color: #fff;
        .lable_text {
          width: 6.38vw;
          height: 5vh;
          background: #b44b7a;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 0.58vw;
          margin-top: 0.3vw;
          font-size: 0.85vw;
          text-align: center;
          line-height: 1.2vw;
          &:first-child {
            margin-top: 0;
            height: 4vh;
            opacity: 0;
          }
        }
      }
      .right_table {
        color: #fff;
        margin-left: 0.3vw;
        .top_lable {
          display: flex;
          align-items: center;
          width: 18vw;
          height: 4vh;
          background: #b44b7a;
          .lable_text {
            width: 33.3%;
            text-align: center;
          }
        }
        .lable_value {
          margin-top: 0.3vw;
          display: flex;
          flex-wrap: wrap;
          width: 18vw;
          .value_row {
            display: flex;
            align-items: center;
            justify-content: space-around;
            height: 5vh;
            font-size: 2vw;
            font-family: "hy";
            font-weight: 400;
            font-style: italic;
            width: 33.3%;
            background: rgba(180, 75, 122, 0.5);
            margin-bottom: 0.3vw;
            .value {
              width: 33.3%;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
</style>