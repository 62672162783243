<template>
  <div class="grb_module">
    <div @click="grbDetail($event)" class="conter">
      <!-- <vueSeamlessScroll
        ref="scroolDiv"
        :hoverStop="true"
        v-if="grb.length > 8"
        :data="grb"
        id="scrollDiv"
      >
        <div class="item_box" v-for="(item, index) in grb" :key="index">
          <div :id="item.id" class="item">
            {{ item.name }}
          </div>
          <div :id="item.id" class="honor">({{ item.honor }})</div>
        </div>
      </vueSeamlessScroll> -->
      <!-- v-else -->
      <div
        class="item_box"
        :style="{ background: backgroundFun(index) }"
        v-for="(item, index) in grb"
        :key="index"
      >
        <!-- <div :id="item.id" class="item">{{ item.name }}({{ item.honor }})</div> -->
        <div :id="item.id" class="item">{{ item.name }}</div>
        <div :id="item.id" class="honor">({{ item.honor }})</div>
      </div>
    </div>
  </div>
</template>
<script>
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  props: {
    grb: Array,
  },
  components: {
    vueSeamlessScroll,
  },
  data() {
    return {};
  },
  watch: {},
  created() {},
  mounted() {},
  computed: {},
  methods: {
    backgroundFun(index) {
      let arr = [1, 4, 5, 8, 9, 12].includes(index + 1);
      return arr
        ? "linear-gradient(270deg, rgba(253,83,146,0.89), rgba(248,111,100,0.89))"
        : "#fe9dc0";
    },
    grbDetail(e) {
      if (e.target.id) {
        let row = { id: e.target.id };
        this.$emit("grbDetail", row);
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.grb_module {
  color: #fff;
  height: 100%;
  .conter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 2vw;
    font-size: 0.83vw;
    height: 100%;
    #scrollDiv {
      width: 100%;
      height: 66vh;
      overflow: hidden;
    }
    .item_box {
      letter-spacing: 0.1vw;
      margin-bottom: 1vw;
      width: 8.9vw;
      text-align: center;
      height: 9.77vh;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      flex-wrap: wrap;
      padding: 0.5vw;
      align-content: space-around;
      .item,
      .honor {
        cursor: pointer;
      }
    }
  }
}
</style>