<template>
  <div class="pyfh_table">
    <div @click="goToPage" class="title">培育孵化</div>
    <div class="table_box">
      <div class="table_title">
        <div class="title_item" v-for="(item, index) in pyfhTable.title">
          <div class="item">{{ item }}</div>
        </div>
      </div>
      <!--  @click="clickRow($event)" -->
      <div class="table_conter">
        <vueSeamlessScroll
          v-if="pyfhTable.data.length > 4"
          :data="pyfhTable.data"
          :class-option="classOption"
          id="scrollDiv"
        >
          <div v-if="pyfhTable.data.length > 0" class="table_data">
            <div class="item_row" v-for="(item, index) in pyfhTable.data">
              <div :id="item.dataId" class="item">{{ item.dataDetailed }}</div>
              <div :id="item.dataId" class="item">{{ item.dataField1 }}</div>
              <div :id="item.dataId" class="item">{{ item.dataField2 }}</div>
            </div>
          </div>
        </vueSeamlessScroll>
        <div v-else="pyfhTable.data.length > 0" class="table_data">
          <div class="item_row" v-for="(item, index) in pyfhTable.data">
            <div :id="item.dataId" class="item">{{ item.dataDetailed }}</div>
            <div :id="item.dataId" class="item">{{ item.dataField1 }}</div>
            <div :id="item.dataId" class="item">{{ item.dataField2 }}</div>
          </div>
        </div>
        <div v-if="!pyfhTable.data.length" class="no_data">暂无数据</div>
      </div>
    </div>
  </div>
</template>
<script>
import vueSeamlessScroll from "vue-seamless-scroll";
import { getData } from "@/api/dtdata";
export default {
  props: {
    pyfhTable: Object,
  },
  components: {
    vueSeamlessScroll,
  },
  data() {
    return {
      classOption: {
        step: 0.5,
      },
    };
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    goToPage() {
      this.$emit("goToPageList", "pyfh");
    },
    async clickRow(e) {
      let id = e.target.id;
      let { data } = await getData(id);
      this.$emit("tableClick", data);
    },
  },
};
</script>
<style lang='scss' scoped>
.pyfh_table {
  .title {
    background: url("../../../assets/image/zhfe/hometitle.png") no-repeat;
    background-size: 100% 100%;
    background-position: center;
    height: 2vw;
    line-height: 2vw;
    padding: 0 1vw;
    margin-top: 2.3vw;
    font-size: 0.9vw;
    // font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #ffd4e4;
  }
  .table_box {
    color: #fff;
    width: 100;
    font-size: 0.8vw;
    .table_title {
      display: flex;
      height: 3vh;
      background: #b44b7a;
      align-items: center;
      padding: 0 1vw;
      margin-top: 0.5vw;
      .title_item {
        width: 13vw;
        &:first-child {
          width: 19vw;
        }
        &:last-child {
          width: 11vw;
          text-align: center;
        }
      }
    }
  }
  .table_conter {
    #scrollDiv {
      width: 100%;
      height: 30vh;
      overflow: hidden;
    }
    .table_data {
      .item_row {
        display: flex;
        align-items: center;
        background: rgba(205, 128, 162, 0.1);
        height: 6vh;
        padding: 0 1vw;
        margin: 0.5vw 0;
        &:nth-child(2n + 2) {
          background: rgba(205, 128, 162, 1);
        }
        .item {
          width: 13vw;
          // overflow: hidden;
          // text-overflow: ellipsis;
          // white-space: nowrap;
          padding-right: 1vw;
          line-height: 1.5vw;
          &:first-child {
            width: 19vw;
          }
          &:last-child {
            width: 11vw;
            text-align: center;
            padding-right: 0vw;
          }
        }
      }
    }
    .no_data {
      text-align: center;
      background: rgba(205, 128, 162, 0.1);
      height: 6vh;
      line-height: 6vh;
      padding: 0 1vw;
    }
  }
}
</style>