<template>
  <div class="main">
    <div class="header">
      <img class="header-logo-ci" src="@/assets/image/zhfe/zhfCI.png" />
      <img class="header-logo" src="@/assets/image/zhfe/zhfelogo.png" />
      <span class="header-txt">智惠妇儿</span>
      <!-- 关闭 刷新 -->
      <div class="idx-middlesx-box3">
        <div class="idx-middle-1">
          <img
            class="fh"
            src="@/assets/image/zhfe/return.png"
            @click="goBack"
          />
          <!-- <img
            class="fh"
            src="@/assets/image/zhfe/icon/gb.png"
            v-show="gb"
            @click="close"
          /> -->
          <img
            class="sx"
            src="@/assets/image/zhfe/icon/sx.png"
            alt=""
            @click="sx"
          />
        </div>
      </div>
    </div>
    <!-- 时间 -->
    <div class="time-box">
      <Date></Date>
    </div>
    <div class="content">
      <div class="left">
        <!-- 先进单位 -->
        <div
          class="xjdw_box"
          :style="{ cursor: isPass == true ? 'pointer' : 'default' }"
          @click="editXjdwEcharts(xjdw)"
        >
          <div class="xjdw_title">
            <div class="title_text">先进单位</div>
            <div class="title_value">
              <span v-for="(item, index) in xjdw.titleValue" :key="index">{{
                item
              }}</span>
            </div>
          </div>
          <div class="echarts_box">
            <div class="left_box bg_box">
              <xjdwEcharts
                title="巾帼文明岗位"
                :xjdwData="xjdw.jgwmgw"
              ></xjdwEcharts>
            </div>
            <div class="right_box bg_box">
              <xjdwEcharts
                title="三八红旗集体"
                title2="(巾帼建功先进单位)"
                :xjdwData="xjdw.sbhqjt"
              ></xjdwEcharts>
            </div>
          </div>
        </div>
        <!-- 先进女性 -->
        <div
          :style="{ cursor: isPass == true ? 'pointer' : 'default' }"
          @click="editXjnxModule(xjnx)"
          class="xjnx_bo"
        >
          <xjnxModule :xjnx="xjnx"></xjnxModule>
        </div>
        <!-- 先进家庭 -->
        <div
          :style="{ cursor: isPass == true ? 'pointer' : 'default' }"
          @click="editXjjtModule(xjjt)"
          class="xjjt_box"
        >
          <xjjtModule :xjjt="xjjt"></xjjtModule>
        </div>
      </div>
      <div class="middle">
        <!-- 光荣榜 -->
        <div class="grb_box">
          <div
            @click="toGtbListPage"
            :style="{ cursor: isPass == true ? 'pointer' : 'default' }"
            class="grb_title"
          >
            <div class="title">光荣榜</div>
          </div>
          <div class="grb_conter">
            <grbModule @grbDetail="grbDetail" :grb="grb"></grbModule>
          </div>
        </div>
      </div>
      <div class="right">
        <!-- 近年获取情况 -->
        <div
          :style="{ cursor: isPass == true ? 'pointer' : 'default' }"
          class="xjdw_module"
        >
          <jnhjqkTable
            @goToPageList="goToPageList"
            @tableClick="tableClick"
            :jnhjqkTable="jnhjqkTable"
          ></jnhjqkTable>
        </div>
        <!-- 培育孵化 -->
        <div
          :style="{ cursor: isPass == true ? 'pointer' : 'default' }"
          class="pyfh_module"
        >
          <pyfhTable
            @goToPageList="goToPageList"
            @tableClick="tableClick"
            :pyfhTable="pyfhTable"
          ></pyfhTable>
        </div>
      </div>
    </div>
    <grbDialog
      @closeDialog="closeDialog"
      v-if="grbDetailDialog"
      :grbDetailDialog="grbDetailDialog"
      :grbRow="grbRow"
    ></grbDialog>
    <leftDialog
      v-if="dialogVisible"
      :editObj="editObj"
      :dialogTitle="dialogTitle"
      :type="moduleType"
      @closeLeftDialog="closeLeftDialog"
    ></leftDialog>
    <rightDialog
      v-if="tableDialogVisible"
      :editObj="editObj"
      :dialogTitle="dialogTitle"
      :type="moduleType"
      @closeRightDialog="closeRightDialog"
    ></rightDialog>
  </div>
</template>
<script>
import { formatDate } from "@/utils/date";
import xjdwEcharts from "./conponents/xjdwEcharts.vue";
import leftDialog from "./conponents/leftDialog.vue";
import rightDialog from "./conponents/rightDialog.vue";
import xjnxModule from "./conponents/xjnxModule.vue";
import xjjtModule from "./conponents/xjjtModule.vue";
import grbModule from "./conponents/grbModule.vue";
import grbDialog from "./conponents/grbDialog.vue";
import jnhjqkTable from "./conponents/jnhjqkTable.vue";
import pyfhTable from "./conponents/pyfhTable.vue";
import { listData } from "@/api/dtdata";
import { getGrbList } from "@/api/zhfeAward";
import Cookies from "js-cookie";
import Date from "@/views/commonComponents/date.vue";
export default {
  components: {
    Date,
    xjdwEcharts,
    leftDialog,
    rightDialog,
    xjnxModule,
    xjjtModule,
    grbModule,
    grbDialog,
    jnhjqkTable,
    pyfhTable,
  },
  data() {
    return {
      editFlag: false,
      moduleType: "",
      dialogVisible: false,
      tableDialogVisible: false,
      dialogTitle: "",
      editObj: {},
      isPass: Cookies.get("pass") && Cookies.get("pass") == "fulian456",
      grbDetailDialog: false,
      grbRow: null,
      gb: false,
      // 先进单位图表
      xjdw: {
        titleValue: [],
        xdata: [],
        jgwmgw: {
          data: {
            ydata: [],
          },
        },
        sbhqjt: {
          data: {
            ydata: [],
          },
        },
      },
      // 先进女性
      xjnx: {
        value: [],
        table: {
          leftLable: ["透明", "总数", "三八红旗手(建功标兵)"],
          dataLable: ["国家级", "省级", "市级"],
          data: [],
        },
      },
      // 先进家庭
      xjjt: {
        data: [],
      },
      // 光荣榜
      grb: [],
      // 先进单位表格
      jnhjqkTable: {
        title: ["奖项", "颁发单位", "获奖级别"],
        data: [],
      },
      // 培育孵化列表
      pyfhTable: {
        title: ["项目", "责任人", "计划完成时间"],
        data: [],
      },
    };
  },
  watch: {},
  async created() {
    if (Cookies.get("pass") && Cookies.get("pass") == "fulian456") {
      this.editFlag = true;
    }
    console.log(this.editFlag);
    await this.getXjdwList();
    await this.getXjnxList();
    await this.getXjjtList();
    await this.getJnhjqkList();
    await this.getPyfhList();
    await this.getGrbList();
  },
  mounted() {},
  methods: {
    goToPageList(type) {
      if (this.isPass) {
        this.$router.push({
          path: "/zhfeAwardCultivation",
          query: { type: type },
        });
      }
    },
    toGtbListPage() {
      if (this.isPass) {
        this.$router.push({ path: "theHonorRoll" });
      }
    },
    closeDialogData(obj) {
      this.$nextTick(() => {
        this.editObj = {};
        this.dialogTitle = "";
        this.moduleType = "";
        if (obj.num == 1) {
          switch (obj.type) {
            case "xjdw":
              this.getXjdwList();
              break;
            case "xjnx":
              this.getXjnxList();
              break;
            case "xjjt":
              this.getXjjtList();
              break;
          }
        }
      });
    },
    closeLeftDialog(obj) {
      this.closeDialogData(obj);
      this.dialogVisible = false;
    },
    closeRightDialog(obj) {
      if (obj.num != 0) {
        if (obj.type == "jnhjqk") {
          this.getJnhjqkList();
        } else {
          this.getPyfhList();
        }
      }
      this.tableDialogVisible = false;
    },
    tableClick(data) {
      if (this.editFlag) {
        if (data.dataName.includes("jnhjqk")) {
          this.moduleType = "jnhjqk";
          this.dialogTitle = "编辑近年获奖情况信息";
        } else {
          this.moduleType = "pyfh";
          this.dialogTitle = "编辑培育孵化信息";
        }
        this.editObj = JSON.parse(JSON.stringify(data));
        this.tableDialogVisible = true;
      }
    },
    editXjjtModule(xjjt) {
      if (this.editFlag) {
        let xjjtDta = JSON.parse(JSON.stringify(xjjt.data));
        let editObj = {
          xdata: [],
          jgwmgw: { data: { ydata: [] } },
          sbhqjt: { data: { ydata: [] } },
        };
        xjjtDta.forEach((item, index) => {
          editObj.xdata.push(item.name);
          editObj.jgwmgw.data.ydata.push(item);
        });
        this.moduleType = "xjjt";
        this.dialogTitle = "编辑先进家庭信息";
        this.editObj = editObj;
        console.log("编辑先进家庭信息", this.editObj);
        this.dialogVisible = true;
      }
    },
    editXjnxModule(xjnx) {
      if (this.editFlag) {
        let xjnxData = JSON.parse(JSON.stringify(xjnx.table.data));
        let editObj = {
          xdata: xjnx.table.dataLable,
          jgwmgw: { data: { ydata: [] } },
          sbhqjt: { data: { ydata: [] } },
        };
        xjnxData.forEach((item) => {
          if (item.dataName.includes("sbhqs")) {
            editObj.sbhqjt.data.ydata.push(item);
          } else {
            editObj.jgwmgw.data.ydata.push(item);
          }
        });
        this.moduleType = "xjnx";
        this.dialogTitle = "编辑先进女性信息";
        this.editObj = editObj;
        console.log("编辑先进女性信息", this.editObj);
        this.dialogVisible = true;
      }
    },
    editXjdwEcharts(xjdw) {
      if (this.editFlag) {
        let editObj = JSON.parse(JSON.stringify(xjdw));
        this.moduleType = "xjdw";
        this.dialogTitle = "编辑先进单位信息";
        this.editObj = editObj;
        // console.log("编辑先进单位信息", this.editObj);
        this.dialogVisible = true;
      }
    },
    closeDialog() {
      this.grbRow = null;
      this.grbDetailDialog = false;
    },
    grbDetail(val) {
      this.grbRow = val;
      this.grbDetailDialog = true;
    },
    queryData(dataTyp = undefined, dataName = undefined) {
      let query = {
        dataModule: "hjqk",
        dataTyp: dataTyp,
        dataName: dataName,
        status: "0",
        pageNum: 1,
      };
      return query;
    },
    async getXjdwList() {
      let rows = await this.listDataFun("hjqk-statistics-xjdw");
      let jgwmgw = [];
      let sbhqjt = [];
      let xdata = [];
      if (rows.length > 0) {
        rows.forEach((item) => {
          item.name = item.dataField1;
          item.value = item.dataDetailed;
          if (item.dataName.includes("jgwmgw")) {
            jgwmgw.push(item);
          }
          if (item.dataName.includes("sbhqjt")) {
            sbhqjt.push(item);
          }
          if (item.dataName.includes("all")) {
            this.xjdw.titleValue = [...item.dataDetailed];
          }
        });
        jgwmgw.forEach((item) => {
          xdata.push(item.name);
        });
        this.xjdw.jgwmgw.data.ydata = jgwmgw;
        this.xjdw.sbhqjt.data.ydata = sbhqjt;
        this.xjdw.xdata = xdata;
      }
    },
    async getXjnxList() {
      let rows = await this.listDataFun("hjqk-statistics-xjnx");
      let value = 0;
      let sbhqs = [];
      let total = [];
      if (rows.length > 0) {
        rows.forEach((item) => {
          if (item.dataName == "hjqk-statistics-xjnx-sbhqs") {
            sbhqs.push(item);
          } else {
            value += item.dataDetailed * 1;
            total.push(item);
          }
        });
        let sbhtsObj = this.xjnxSwitchFun(sbhqs);
        let totalObj = this.xjnxSwitchFun(total);
        rows = [...total, ...sbhqs];
        this.xjnx.table.data = rows;
        // this.xjnx.table.data.push(totalObj, sbhtsObj);
        value = value + "";
        this.xjnx.value = [...value];
        // console.log("先进女性列表", this.xjnx);
      }
    },
    async getXjjtList() {
      let rows = await this.listDataFun("hjqk-statistics-xjjt");
      if (rows.length > 0) {
        // rows = rows.sort(function (a, b) {
        //   return b.sort - a.sort;
        // });
        let arr = [];
        rows.map((item) => {
          item.name = item.dataName;
          item.value = item.dataDetailed;
        });
        this.xjjt.data = rows;
        // console.log("先进家庭", this.xjjt);
      }
    },
    async getJnhjqkList() {
      let rows = await this.listDataFun(
        "hjqk-statistics",
        "hjqk-statistics-jnhjqk"
      );
      // console.log("rows.length % 2 != 0", rows.length % 2 != 0);
      if (rows.length % 2 != 0) {
        rows.push({ dataDetailed: "", dataField1: "", dataField2: "" });
      }
      this.jnhjqkTable.data = rows;
      // console.log("近年获奖情况", rows);
    },
    async getPyfhList() {
      let rows = await this.listDataFun(
        "hjqk-statistics",
        "hjqk-statistics-pyfh"
      );
      this.pyfhTable.data = rows;
      // console.log("培育孵化", rows);
    },
    async getGrbList() {
      let { rows } = await getGrbList({ pageNum: 1, pageSize: 12 });
      this.grb = rows;
    },
    async listDataFun(dataTyp = undefined, dataName = undefined) {
      let query = this.queryData(dataTyp, dataName);
      let { rows } = await listData(query);
      let data = rows;
      return data;
    },
    xjnxSwitchFun(arr) {
      let obj = {};
      arr.forEach((item) => {
        switch (item.dataField1) {
          case "国家级":
            obj.nationalLevel = item.dataDetailed;
            break;
          case "省级":
            obj.provincialLevel = item.dataDetailed;
            break;
          case "市级":
            obj.municipalLevel = item.dataDetailed;
            break;
        }
      });
      return obj;
    },
    // 清除cookies
    close() {
      this.$cookies.remove("pass"); // return this
      this.$router.go(0); //刷新当前页面
    },
    sx() {
      this.$router.go(0); //刷新当前页面
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang='scss' scoped>
@import "./index.scss";
</style>